import React, { useState, useEffect } from 'react';
import './DeploymentPage.css';
import DeploymentHistory from './DeploymentHistory';
import Modal from './Modal';
import { signOut } from 'aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const DeploymentPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [deploymentType, setDeploymentType] = useState('');
  const [activeTab, setActiveTab] = useState('deploymentOptions');
  const [lightsailOptions, setLightsailOptions] = useState(null);
  const [showAdvancedModal, setShowAdvancedModal] = useState(false);

  useEffect(() => {
    const fetchLightsailOptions = async () => {
      try {
        // Use environment variable or fallback to localhost
        const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL || 'http://localhost:3001';
        const response = await fetch(`${serverBaseUrl}/api/lightsail/config-options`);
        if (!response.ok) {
          throw new Error('Error fetching Lightsail config options');
        }
        const options = await response.json();
        setLightsailOptions(options);
      } catch (error) {
        console.error('Error fetching Lightsail config options:', error);
        alert('Failed to fetch Lightsail configuration options. Please try again.');
      }
    };
  
    fetchLightsailOptions();
  }, []);

  const deploymentOptions = [
    { id: 1, name: 'Deploy Monolith', description: '', sampleContent: 'The Monolith deployment takes up to 10 minutes.', actionLink: 'monolith' },
    { id: 2, name: 'Deploy Highly Available', description: '', sampleContent: 'The Highly-Available deployment takes up to 20 minutes.', actionLink: 'highlyavailable' },
    { id: 3, name: 'Deploy LightSail', description: '', sampleContent: 'The Lightsail deployment takes up to 10 minutes.', actionLink: 'lightsail' },
  ];

  const handleDeploymentClick = (option) => {
    setModalContent(option.sampleContent);
    setModalTitle(option.name);
    setDeploymentType(option.actionLink);
    setModalOpen(true);
    setShowAdvancedModal(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setShowAdvancedModal(false);
  };

  const handleAdvancedDeployment = () => {
    setDeploymentType('lightsail');
    setShowAdvancedModal(true);
    setModalOpen(false);
    console.log('Advanced deployment button clicked');
  };

  const handleAdvancedDeploymentClose = () => {
    setShowAdvancedModal(false);
  };

  const handleAdvancedDeploy = async (deploymentData) => {
    try {
      const response = await fetch('http://localhost:3001/deploy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deploymentType: 'lightsail',
          ...deploymentData,
        }),
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      console.log('Advanced deployment initiated:', data);
      setShowAdvancedModal(false);
      setModalOpen(false);
    } catch (error) {
      console.error('Deployment error:', error);
      alert('Error initiating deployment. Please check the console for details.');
    }
  };

  const handleBasicDeploy = async () => {
    try {
      const response = await fetch('http://localhost:3001/deploy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deploymentType }),
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      const wordpressUrl = data.details && data.details.WordPress_URL ? data.details.WordPress_URL.value : null;
      const message = wordpressUrl ? `Deployment initiated successfully: ${wordpressUrl}` : data.message || "Deployment process initiated. Check server logs for details.";
      alert(message);
      console.log('Basic deployment initiated:', data);
      setModalOpen(false);
    } catch (error) {
      console.error('Deployment error:', error);
      alert('Error initiating deployment. Please check the console for details.');
    }
  };
 
 
  const SettingsContent = () => (
    <div className="about">
      <h4>XMOPS Accelerate</h4>
      <p><strong>Designed and Developed by</strong></p>
      <p>
        Dollar Karan Preet Singh<br />
        Musrat Jahan Rimu<br />
        Ramandeep Singh Turna<br />
        Amandeep Singh
      </p>
      <p><strong>Version:</strong> 1.21</p>
      <p className="help">Documentation: <a href="https://developer.xmopsaccelerate.com" target="_blank" rel="noopener noreferrer">XMOPS Accelerate Documentation</a></p>
      <p className="help">Official Website: <a href="https://www.xmopsaccelerate.com" target="_blank" rel="noopener noreferrer">XMOPS Accelerate</a></p>
      <div className="social-icons">
        <a href="https://www.twitter.com/xmopsaccelerate" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a href="https://www.github.com/xmopsaccelerate" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a href="https://www.instagram.com/xmopsaccelerate" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>
  );

  const TabButton = ({ tabKey, children }) => (
    <button className={`tab-button ${activeTab === tabKey ? 'active' : ''}`} onClick={() => setActiveTab(tabKey)}>
      {children}
    </button>
  );

  const AdvancedDeploymentModal = ({ isOpen, onClose, lightsailOptions, onDeploy }) => {
    const [instanceName, setInstanceName] = useState('');
    const [region, setRegion] = useState('');
    const [blueprint, setBlueprint] = useState('');
    const [bundlePlan, setBundlePlan] = useState('');
    const [publicSshKey, setPublicSshKey] = useState('');
  
    const handleDeploy = async () => {
      const deploymentData = {
        instanceName,
        region,
        blueprint,
        bundlePlan,
        publicSshKey,
      };
      await onDeploy(deploymentData);
    };
  
    if (!isOpen) return null;
  
    if (!lightsailOptions) {
      return (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Advanced Lightsail Deployment</h3>
              <button type="button" onClick={onClose} className="close-button">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>Loading Lightsail configuration options...</p>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h3 className="modal-title">Advanced Lightsail Deployment</h3>
            <button type="button" onClick={onClose} className="close-button">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="instanceName">Instance Name</label>
              <input
                type="text"
                id="instanceName"
                value={instanceName}
                onChange={(e) => setInstanceName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                id="region"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                <option value="">Select a region</option>
                {lightsailOptions.regions.map((region) => (
                  <option key={region.name} value={region.name}>
                    {region.displayName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="blueprint">Blueprint</label>
              <select
                id="blueprint"
                value={blueprint}
                onChange={(e) => setBlueprint(e.target.value)}
              >
                <option value="">Select a blueprint</option>
                {lightsailOptions.blueprints.map((blueprint) => (
                  <option key={blueprint.id} value={blueprint.id}>
                    {blueprint.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="bundlePlan">Bundle Plan</label>
              <select
                id="bundlePlan"
                value={bundlePlan}
                onChange={(e) => setBundlePlan(e.target.value)}
              >
                <option value="">Select a bundle plan</option>
                {lightsailOptions.bundles.map((bundle) => (
                  <option key={bundle.id} value={bundle.id}>
                    {bundle.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="publicSshKey">Public SSH Key</label>
              <textarea
                id="publicSshKey"
                value={publicSshKey}
                onChange={(e) => setPublicSshKey(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={handleDeploy} className="modal-action-button">
              Deploy
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <div className="page-background">
        <div className="blob top-right"></div>
        <div className="blob bottom-left"></div>
      </div>
      <div className={`app-container ${isModalOpen || showAdvancedModal ? 'content-blur' : ''}`}>
        <div className="main-content">
          <div className="login-card">
            <div className="login-card-header">
              <header className="login-card__header">
                <img src={`${process.env.PUBLIC_URL}/XMOPS Accelerate.svg`} alt="XMOPS Accelerate" width="160" />
              </header>
              <div className="tab-container">
                <TabButton tabKey="deploymentOptions">Deployment Options</TabButton>
                <TabButton tabKey="deploymentHistory">Deployment History</TabButton>
                <TabButton tabKey="settings">About</TabButton>
              </div>
            </div>
            {activeTab === 'deploymentOptions' && (
              <div className="login-card__content">
                <div className="main-card-section">
                  <h2 className="deployment-heading">Select a Deployment Option</h2>
                  <div className="deployment-buttons">
                    {deploymentOptions.map((option) => (
                      <button key={option.id} className="deployment-button" onClick={() => handleDeploymentClick(option)}>
                        <strong>{option.name}</strong><br />
                        <span>{option.description}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'deploymentHistory' && <DeploymentHistory />}
            {activeTab === 'settings' && <SettingsContent />}
          </div>
          <footer className="login-footer">
            <hr />
            <button className="logout-button" onClick={signOut}>Sign Out</button>
          </footer>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          title={modalTitle}
          onClose={handleCloseModal}
          content={
            <div className="sample-content">
              <p>{modalContent}</p>
            </div>
            
          }
          
          onCustomAction={handleBasicDeploy}
          customActionText="Basic Deploy"
          secondaryAction={deploymentType === 'lightsail' ? handleAdvancedDeployment : null}
          secondaryActionText={deploymentType === 'lightsail' ? 'Advanced Deployment' : null}
          
        />
      )}
      {showAdvancedModal && (
        <AdvancedDeploymentModal
          isOpen={showAdvancedModal}
          onClose={handleAdvancedDeploymentClose}
          lightsailOptions={lightsailOptions}
          onDeploy={handleAdvancedDeploy}
        />
      )}
      {console.log('lightsailOptions:', lightsailOptions)}
      {console.log('showAdvancedModal:', showAdvancedModal)}
    </div>
  );
};

export default DeploymentPage;